import React from 'react'
import SectionContact from '../components/Contact/SectionContact'
import * as styles from '../styles/local.module.scss'
import { graphql } from 'gatsby';
import Seo from '../components/Shared/Seo/Seo'
const PageContact = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            seoTitle,
            slug,
            seoDescription,
            messageButtonTitle,
            contactUsDescriptionBottom,
            contactUsDescriptionTop
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
}) => {
    const data = {
        contactUsDescriptionTop,
        contactUsDescriptionBottom,
        messageButtonTitle
    }
    return (
        <div>
            <h1 style={{ display: 'none' }}>{seoTitle}</h1>

            <Seo
                title={seoTitle}
                description={seoDescription}
                lang={pageContext.node_locale}
                hrefLangs={allLinks}
                slug={slug}
            />
            <div className={styles.container}>
                <SectionContact data = {data}/>
            </div>
        </div>
    )
}

export const ContactQuery = graphql`
query ContactQuery($id: String!, $contentful_id: String!){
    contentfulPageCustom(id: {eq: $id}){
        seoTitle
        slug
        seoDescription
        contactUsDescriptionTop{
            raw
        }
        contactUsDescriptionBottom{
            raw
        }
        messageButtonTitle
    }
    allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
        nodes {
            slug
            node_locale
        }
    }
}
`

export default PageContact