import React, { useEffect } from 'react'
import * as styles from './section-contact.module.scss'
import { RichText } from '../Shared/RichText/RichText'
import { useLocale, useSiteQuery } from '../../hooks'
import "./iframe.scss";
import { cookieLang } from '../../utils/mapping';
const SectionContact = ({data}) => {
    const {messageButtonTitle, contactUsDescriptionBottom, contactUsDescriptionTop} = data
    const { contactUsTitle, contactUsContent, carelinesScript, contactFormTitle } = useSiteQuery()
    const locale = useLocale()
    const language = cookieLang[locale]

    const zendesk = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "ze-snippet")
        script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=6ca65b6d-456a-4555-9ac6-582ffb96a3a0`)
        script.setAttribute('async', 'true')
        script.onload = () => {
            zendeskLocale()
        }
        document.body.appendChild(script)
    }

    const zendeskFormHide = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        let innerHTML = ''
        script.innerHTML = `
            window.zESettings = {
                webWidget: {
                    chat: { suppress: true },
                    helpCenter: { suppress: true },
                    talk: { suppress: true },
                    answerBot: { suppress: true },
                }
            };
            `
        document.body.appendChild(script)
    }

    const zendeskLocale = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        let innerHTML = ''
        script.innerHTML = `
                zE('webWidget', 'setLocale', '${language}');
            `
        document.body.appendChild(script)
    }

    const openPopup = () => {
        zE('webWidget', 'show');
        zE('webWidget', 'open');
      }

    useEffect(() => {
        if (!document.getElementById('launcher')) {
            zendeskFormHide()
            zendesk()
        }
        else
            zE('webWidget', 'show');

        return () => {
            zE('webWidget', 'hide');
        }
    })

    return (
        <div>
            <article className={styles.contactContainer}>
                <h1 className={styles.title}>{contactUsTitle}</h1>
                <div className={styles.content}>
                    <RichText content={contactUsDescriptionTop} />
                    <button className={styles.button} onClick={() => openPopup()}> {messageButtonTitle} </button>
                    <RichText content={contactUsDescriptionBottom}/>
                </div>
                {/* <legend className={styles.title}>{contactFormTitle}</legend> */}
                <section>
                </section>
            </article>
        </div>
    )
}

export default SectionContact